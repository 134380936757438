import { useState, useEffect } from 'react'
import axios from 'axios'

import Caixa from "../../Biblioteca/Caixa"
import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import Textarea from '../../Biblioteca/Textarea'
import enviar from '../../Biblioteca/Funcoes/enviar'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const GetDfdExtra = () => {
    const [dados, setDados] = useState([])
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        axios('/api/dfdExtra/getDfdsExtra')
            .then(res => {
                const dadosComModo = res.data.map(item => ({
                    ...item,
                    modo: "fixed"
                }))
                setDados(dadosComModo)
            })
            .catch(error => alert(error.response.data.message))
    }, [])

    const updateField = (e, index) => {
        const { name, value } = e.target
        let tempDados = [...dados]
        tempDados[index] = {
            ...tempDados[index],
            [name]: value,
        }
        setDados(tempDados)
    }

    const arquivar = (item) => {
        if (window.confirm("Tem certeza de que deseja arquivar este item?")) {
            axios.post('/api/dfdExtra/arquivar', item)
                .then(res => {
                    alert(res.data)
                    window.location = '/getdfdextra'
                })
                .catch(error => alert(error.response.data.message))
        }
    }

    const alterarModo = (index) => {
        let tempDados = [...dados]
        const novoModo = tempDados[index].modo === "fixed" ? "" : "fixed"
        tempDados[index] = {
            ...tempDados[index],
            modo: novoModo,
        }
        setDados(tempDados)
    }

    return (
        <>
            {dados.map((el, index) => {
                return (
                    <div key={`key-${index}`}>
                        <Caixa marginBottom="4vh">
                            {!el.idItem && !el.incluidoItensPca ? (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Botao texto="Editar" funcao={() => alterarModo(index)} cor="rgb(51, 52, 143)" />
                                </div>
                            ) : (<></>)}
                            <h4 style={{ marginBottom: "2vh" }}>Solicitante: {el?.autor} - {el?.regional}</h4>
                            <Input texto="Cód. Central de Compras" nome="codCentral" tipo="number" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.codCentral} widthWeb="14vw" widthMobile="80vw" />
                            <Input texto="Descrição" nome="descricao" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.descricao} widthWeb="24vw" widthMobile="80vw" />
                            <Input texto="Medida(und, caixa, L, kg etc)" nome="und" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.und} widthWeb="16vw" widthMobile="80vw" />
                            <Input texto="Valor Estimado" nome="valorEstimado" tipo="moeda" dado={el?.valorEstimado || null}
                                valor={formatCurrency(el?.valorEstimado)} modo={el.modo} funcao={(e) => updateField(e, index)} widthWeb="14vw" widthMobile="80vw" />
                            <Input texto="Nome do Item" nome="objeto"
                                valor={el?.objeto} widthWeb="14vw" widthMobile="80vw" modo={el.modo} funcao={(e) => updateField(e, index)} />
                            <Select texto="Tipo do CAT" nome="tipoCat" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.tipoCat} widthWeb="24vw" widthMobile="80vw"
                                options={["Material", "Serviço"]} />
                            <Input texto="CATMAT/SER" nome="catMatSer" tipo="number" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.catMatSer} widthWeb="16vw" widthMobile="80vw" />
                            <Select texto="Ação" nome="acao" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.acao} widthWeb="14vw" widthMobile="80vw"
                                options={["4211", "4216", "2143", "1157", "1614", "2216", "2217", "4392", "4393",
                                    "4938", "6106", "0703", "751", "4391", "4780"]} />
                            <Select texto="Natureza" nome="natureza" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.natureza} widthWeb="14vw" widthMobile="80vw"
                                options={["339030", "339033", "339039", "339139", "449039", "449051", "449052"]} />
                            <Select texto="Área" nome="areaEipcp" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.areaEipcp} widthWeb="24vw" widthMobile="80vw"
                                options={["Atendimento Pré-Hospitalar", "Atividades Técnicas", "Combate a Incêndio Florestal",
                                    "Combate a Incêndio Urbano", "Materiais de Construção", "Decoração e Eventos", "Educação Física",
                                    "Embarcações, Motores e Reboques", "Ensino, Instrução e Pesquisa", "Equipamento de Som e afins",
                                    "Ferramentas e Equipamentos Gerais de Busca e Salvamento", "Gabinete Médico e Odontológico",
                                    "Gêneros Alimentícios", "Locação de Imóvel", "Materiais de Cozinha", "Material Bélico",
                                    "Material de Limpeza", "Mergulho", "Mobiliário, eletrodomésticos e afins", "Obras, serviços de engenharia ou de manutenção predial",
                                    "Operações Aquáticas", "Operações Aéreas", "Operações com Cães", "Operações em Altura",
                                    "Outras Ferramentas e Equipamentos", "Outros Serviços", "Papelaria e Escritório", "Projetos Sociais",
                                    "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra",
                                    "Seleções internas e Concursos Público",
                                    "Tecnologia da Informação", "Uniformes e acessórios", "Viaturas - Locação",
                                    "Viaturas - Manutenção", "Viaturas Administrativas - Aquisição", "Viaturas Operacionais - Aquisição"]} />
                            <Input texto="Qtd. Estimada" nome="qtdEstimada" tipo="number" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.qtdEstimada} widthWeb="16vw" widthMobile="80vw" />
                            <Textarea texto="Justificativa" nome="justificativa" modo={el.modo} funcao={(e) => updateField(e, index)}
                                valor={el?.justificativa} widthWeb="74vw" widthMobile="80vw" />
                            <div style={{ textAlign: "center" }}>
                                <Botao texto="ARQUIVAR" cor="red" funcao={() => arquivar(el)}
                                    display="inline-block" widthMobile="40vw" disabled={disabled} />
                                {!el.idItem && !el.incluidoItensPca ? (
                                    <Botao texto="APROVAR ITEM" cor="rgb(51, 52, 143)" funcao={() => enviar('/api/dfdExtra/aprovarItem', el, "/getdfdextra")}
                                        display="inline-block" widthWeb="14vw" widthMobile="40vw" disabled={disabled} />
                                ) : (<></>)}
                            </div>
                        </Caixa>
                    </div>
                )
            })}
        </>
    )
}

export default GetDfdExtra