import { useState } from "react"
import axios from 'axios'

import Caixa from "../../Biblioteca/Caixa"
import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import Textarea from '../../Biblioteca/Textarea'
import enviar from '../../Biblioteca/Funcoes/enviar'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const SolicitarItem = () => {
    const [dados, setDados] = useState({ user: localStorage.nome, regional: localStorage.regionalUser })
    const [search, setSearch] = useState()
    const [searchResults, setSearchResults] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [keyDiv, setKey] = useState(0)

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }
    const updateSearch = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setSearch(tempState)
    }

    const buscaItem = () => {
        axios.post('/api/pca/buscarItem', search)
            .then(res => setSearchResults(res.data))
            .catch(error => {
                alert(error.response.data.message)
                setSearchResults([])
            })
    }

    const adicionarItem = (item) => {
        const newItem = {
            idItem: item._id,
            codCentral: item.codCentral,
            descricao: item.descricao,
            und: item.und,
            valorEstimado: item.valorEstimado,
            objeto: item.objeto,
            tipoCat: item.tipoCat,
            catMatSer: item.catMatSer,
            acao: item.acao,
            natureza: item.natureza,
            areaEipcp: item.areaEipcp
        }

        setDados((prevDados) => ({
            ...prevDados,
            ...newItem
        }))
        setKey(prevstate => prevstate + 1)
    }

    return (
        <>
            <div key={keyDiv}>
                <Botao texto="CONSULTA CÓDIGOS" cor="#33348F" widthWeb="20vw" widthMobile="40vw"
                    funcao={() => window.open("https://drive.google.com/drive/folders/14fOjZter6sHmIBQdovgMZ6lgKxNEYmIa")} />
                <Caixa marginBottom="4vh">
                    <Input texto="Cód. Central de Compras" nome="codCentral" tipo="number" funcao={updateField}
                        valor={dados?.codCentral} widthWeb="14vw" widthMobile="80vw" />
                    <Input texto="Descrição" nome="descricao" funcao={updateField}
                        valor={dados?.descricao} widthWeb="24vw" widthMobile="80vw" />
                    <Input texto="Medida(und, caixa, L, kg etc)" nome="und" funcao={updateField}
                        valor={dados?.und} widthWeb="16vw" widthMobile="80vw" />
                    <Input texto="Valor Estimado" nome="valorEstimado" tipo="moeda" dado={dados?.valorEstimado || null}
                        valor={dados?.valorEstimado} funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
                    <Input texto="Nome do Item" nome="objeto"
                        valor={dados?.objeto} widthWeb="14vw" widthMobile="80vw" funcao={updateField} />
                    <Select texto="Tipo do CAT" nome="tipoCat" funcao={updateField}
                        widthWeb="24vw" widthMobile="80vw"
                        options={["Material", "Serviço"]} />
                    <Input texto="CATMAT/SER" nome="catMatSer" tipo="number" funcao={updateField}
                        valor={dados?.catMatSer} widthWeb="16vw" widthMobile="80vw" />
                    <Select texto="Ação" nome="acao" funcao={updateField}
                        valor={dados?.acao} widthWeb="14vw" widthMobile="80vw"
                        options={["4211", "4216", "2143", "1157", "1614", "2216", "2217", "4392", "4393",
                            "4938", "6106", "0703", "751", "4391", "4780"]} />
                    <Select texto="Natureza" nome="natureza" funcao={updateField}
                        valor={dados?.natureza} widthWeb="14vw" widthMobile="80vw"
                        options={["339030", "339033", "339039", "339139", "449039", "449051", "449052"]} />
                    <Select texto="Área" nome="areaEipcp" funcao={updateField}
                        valor={dados?.areaEipcp} widthWeb="24vw" widthMobile="80vw"
                        options={["Atendimento Pré-Hospitalar", "Atividades Técnicas", "Combate a Incêndio Florestal",
                            "Combate a Incêndio Urbano", "Materiais de Construção", "Decoração e Eventos", "Educação Física",
                            "Embarcações, Motores e Reboques", "Ensino, Instrução e Pesquisa", "Equipamento de Som e afins",
                            "Ferramentas e Equipamentos Gerais de Busca e Salvamento", "Gabinete Médico e Odontológico",
                            "Gêneros Alimentícios", "Locação de Imóvel", "Materiais de Cozinha", "Material Bélico",
                            "Material de Limpeza", "Mergulho", "Mobiliário, eletrodomésticos e afins", "Obras, serviços de engenharia ou de manutenção predial",
                            "Operações Aquáticas", "Operações Aéreas", "Operações com Cães", "Operações em Altura",
                            "Outras Ferramentas e Equipamentos", "Outros Serviços", "Papelaria e Escritório", "Projetos Sociais",
                            "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra",
                            "Seleções internas e Concursos Público",
                            "Tecnologia da Informação", "Uniformes e acessórios", "Viaturas - Locação",
                            "Viaturas - Manutenção", "Viaturas Administrativas - Aquisição", "Viaturas Operacionais - Aquisição"]} />
                    <Input texto="Qtd. Estimada" nome="qtdEstimada" tipo="number" funcao={updateField}
                         widthWeb="16vw" widthMobile="80vw" />
                    <Textarea texto="Justificativa" nome="justificativa" funcao={updateField}
                         widthWeb="74vw" widthMobile="80vw" />
                    <div>
                        <Botao texto="ENVIAR" funcao={() => enviar('/api/dfdExtra/solicitar', dados, "/")}
                            widthMobile="40vw" disabled={disabled} />
                    </div>
                </Caixa>
            </div>
            <div style={{ marginLeft: "5vw", display: "inline-block" }}>
                <Input texto="Buscar Item" nome="item" widthWeb="30vw" widthMobile="80vw" funcao={updateSearch} />
                <Botao texto="BUSCAR" funcao={buscaItem}
                    align="left" widthMobile="40vw" />
            </div>
            {searchResults.length > 0 ? (<>
                <div className="scroll-tabela" style={{ width: "90vw", margin: "2vh auto 2vh auto" }}>
                    <table className="tabelaNovoDfd">
                        <tr>
                            <th className="thNovoDfd" style={{ width: "15vw" }}>OBJETO</th>
                            <th className="thNovoDfd" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                            <th className="thNovoDfd">UNIDADE</th>
                            <th className="thNovoDfd">VALOR ESTIMADO</th>
                            <th className="thNovoDfd">ÁREA</th>
                            <th className="thNovoDfd">ADICIONAR</th>
                        </tr>
                        <tbody>
                            {searchResults.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="tdNovoDfd">{el.objeto}</td>
                                        <td className="tdNovoDfd">{el.descricao}</td>
                                        <td className="tdNovoDfd">{el.und}</td>
                                        <td className="tdNovoDfd">{formatCurrency(el.valorEstimado)}</td>
                                        <td className="tdNovoDfd">{el.areaEipcp}</td>
                                        <td className="tdNovoDfd">
                                            <Botao texto="ADICIONAR" funcao={() => adicionarItem(el)}
                                                widthMobile="40vw" />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>) : (<></>)}
        </>
    )
}

export default SolicitarItem