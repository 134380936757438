import { useState, useEffect } from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'
import converterData from '../../Biblioteca/Funcoes/converterData'

const acaoMapping = {
    "1º CRBM": "4855 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 1º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.102",
    "2º CRBM": "4856 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 2º Comando Regional Bombeiro Militar  / Unidade Financeira  - 23.103",
    "3º CRBM": "4857 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 3º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.104",
    "4º CRBM": "6009 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 4º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.106",
    "4211": "SEGUROS E TAXAS DE VEÍCULOS",
    "4216": "MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
    "2143": "FORMAÇÃO, ESPECIALIZAÇÃO E CAPACITAÇÃO DE BOMBEIRO MILITAR",
    "1157": "CONSTRUÇÕES E REFORMAS DE IMÓVEIS DO CBMPB",
    "1614": "AQUISICÃO E LOCAÇÃO DE IMÓVEIS PARA O CBMPB",
    "2216": "AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE AERONAVE",
    "2217": "AQUISIÇÃO E MANUTENÇÃO DE MATERIAL BELICO",
    "4392": "PREVENÇÃO, FISCALIZAÇÃO E PERÍCIA DE INCÊNDIO",
    "4393": "COMBATE A INCENDIO, SALVAMENTO E APH",
    "4938": "AQUISIÇÃO DE SEMOVENTES E EQUIPAMENTOS DE ADESTRAMENTO",
    "1511": "DESENVOLVIMENTO DE PROGRAMAS E PROJETOS SOCIAIS EDUCATIVOS E DE PREVENÇÃO",
    "0703": "DESPESAS DE EXERCÍCIOS ANTERIORES",
    "751": "Indenizações e restituições",
    "4391": "AQUISIÇÃO DE VIATURAS, PEÇAS, ACESSÓRIOS E SERVIÇOS",
    "4780": "SEGUROS E TAXAS DE AERONAVES",
    "6106": "DESENVOLVIMENTO DE PROGRAMAS E PROJETOS DE RESPONSABILIDADE SOCIAL PARA OS PÚBLICOS INTERNO E EXTERNO "
}

const naturezaMapping = {
    "339030": "Material de Consumo",
    "339033": "Passgens e despesas com locomoção",
    "339039": "Outros Serviços de Terceiros - Pessoa Jurídica",
    "339139": "Outros Serviços de Terceiros - Pessoa Jurídica",
    "449039": "Outros Serviços de Terceiros - Pessoa Jurídica - Capital",
    "449051": "Obras e Instalações",
    "449052": "Equipamentos e Material Permanente",
    "449092": "Despesas de Exercícios Anteriores - Capital"
}

const TabelaGestaoItens = () => {
    const [tabelas, setTabelas] = useState([])
    const [keyDiv, setKey] = useState(0)
    const [anoPreenchimento, setAnoPreenchimento] = useState("")
    const [fonte, setFonte] = useState("")

    useEffect(() => {
        if (fonte && anoPreenchimento) {
            const url = '/api/dal/combinarDados'
            axios.post(url, { fonte: fonte, anoPreenchimento: parseInt(anoPreenchimento) })
                .then(res => {
                    setTabelas(res.data)
                    setKey(prevKey => prevKey + 1)
                })
                .catch(error => console.error(error))
        }
    }, [fonte, anoPreenchimento])

    const updateAno = (e) => {
        const { value } = e.target
        setAnoPreenchimento(value)
    }
    const updateFonte = (e) => {
        const { value } = e.target
        setFonte(value)
    }

    return (
        <>
            {fonte === "" || anoPreenchimento === "" ? (
                <div style={{ margin: "1vw 0 0 4vw" }}>
                    <Select texto="Fonte" nome="fonte" valor={fonte}
                        funcao={updateFonte} widthWeb="26vw" widthMobile="80vw"
                        options={["CBM", "FUNESBOM"]} />
                    <Select texto="Ano de Preenchimento" tipo="number" nome="anoPreenchimento" valor={anoPreenchimento}
                        funcao={updateAno} widthWeb="26vw" widthMobile="80vw"
                        options={[2024]} />
                </div>
            ) : (
                <>
                    <div key={keyDiv} style={{ overflowX: "auto" }}>
                        {tabelas && Object.keys(tabelas).length > 0 ? (
                            <div style={{ maxHeight: '92vh', overflowY: 'auto', position: 'relative' }}>
                                <table className="tableDfd" style={{ width: '150vw', tableLayout: 'fixed' }}>
                                    <thead>
                                        <tr>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>JÁ FOI TRAMITADO VIA DFD?</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>TRAMITADO</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>PBDOC</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "4vw" }}>Nº DFD</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "3vw" }}>ITEM</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>ÁREA</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "4vw" }}>CÓDIGO</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>CLASSE CATMAT/SER</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "8vw" }}>OBJETO</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "20vw" }}>DESCRIÇÃO DO OBJETO </th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>UND. DE MEDIDA</th>
                                            <th className="thDfdGeral" colSpan="3" style={{ width: "9vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>QTD. ESTIMADA</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>VALOR ESTIMADO UNITÁRIO</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 2025</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>GRAU DE PRIORIDADE</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>AÇÃO ORÇAMENTÁRIA</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>NATUREZA DE DESPESA</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>DESCRIÇÃO DA AÇÃO ORÇAMENTÁRIA</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>DESCRIÇÃO DA NATUREZA DE DESPESA</th>
                                        </tr>
                                        <tr>
                                            <th className="thDfdGeral">2021</th>
                                            <th className="thDfdGeral">2022</th>
                                            <th className="thDfdGeral">2023</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {RenderRows()}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>Carregando a Tabela...</p>
                        )}
                    </div>
                </>)}
        </>
    )

    function RenderRows() {
        let rows = []
        Object.keys(tabelas).forEach(obj => {
            const items = tabelas[obj].items
            items.forEach((el, index) => {
                const descricao = el.descricao.length > 200 ?
                    el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao

                if (el.totalQtdEstimada > 0) {
                    rows.push(
                        <tr key={`objeto${index}-${el._id}`} style={{ backgroundColor: el.cor }}>
                            <td className="tdDfdGeral">{el.tramitadoDfd}</td>
                            <td className="tdDfdGeral">{el.data}</td>
                            <td className="tdDfdGeral">{el.pbdoc}</td>
                            <td className="tdDfdGeral">{el.numeroDfd}</td>
                            <td className="tdDfdGeral">{el.indexPCA}</td>
                            <td className="tdDfdGeral">{el.areaEipcp}</td>
                            <td className="tdDfdGeral">{el?.codCentral || "Sem correspondência no SGC"}</td>
                            <td className="tdDfdGeral">{el?.catMatSer}</td>
                            <td className="tdDfdGeral">{el.objeto}</td>
                            <td className="tdDfdGeral">{descricao}</td>
                            <td className="tdDfdGeral">{el.und}</td>
                            <td className="tdDfdGeral">{el.totalAno1}</td>
                            <td className="tdDfdGeral">{el.totalAno2}</td>
                            <td className="tdDfdGeral">{el.totalAno3}</td>
                            <td className="tdDfdGeral">{el.totalQtdEstimada}</td>
                            <td className="tdDfdGeral">{formatCurrency(el.valorEstimado)}</td>
                            <td className="tdDfdGeral">{formatCurrency(el.totalQtdEstimada * el.valorEstimado)}</td>
                            <td className="tdDfdGeral">{el.highestPriority}</td>
                            <td className="tdDfdGeral">{el?.acao}</td>
                            <td className="tdDfdGeral">{el?.natureza}</td>
                            <td className="tdDfdGeral">{acaoMapping[el?.acao]}</td>
                            <td className="tdDfdGeral">{naturezaMapping[el?.natureza]}</td>
                        </tr>
                    )
                }
            })
        })
        return rows
    }
}

export default TabelaGestaoItens
